import React from 'react'
import PropTypes from 'prop-types'

import Icon from './icon'

const MarketingToolCard = ({
  url,
  logo,
  title,
  price,
  summary,
  visitToolSiteLinkText
}) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ height: '100%' }}
    className="border-box border-box--hover p-32 p-lg-40 d-block"
  >
    <div className="row">
      <div className="col-8">
        <figure className="mb-16" style={{ width: 32 }}>
          {logo}
        </figure>
      </div>
      <div className="col-4 fw-text-bold">{price}</div>
    </div>
    <h5 className="fw-head-semibold">{title} </h5>
    <p className="mt-24">{summary}</p>
    <div className="mt-24">
      {visitToolSiteLinkText}
      <Icon className="ml-8" />
    </div>
  </a>
)

MarketingToolCard.defaultProps = {}

MarketingToolCard.propTypes = {
  url: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired
}

export default MarketingToolCard
