import React, { useState } from 'react'

import MarketingToolCard from '@c/marketing-tool-card'
import Icon from '@c/icon'
import ProjectImage from '@/components/project-image'

const List = ({
  id,
  title,
  className,
  tools,
  displayMoreButtonText,
  visitToolSiteLinkText
}) => {
  const [showAll, setShowAll] = useState(true)
  const maxInitialItems = 4

  return (
    <section id={id} className={className}>
      <h2>{title}</h2>

      {/* cards */}
      <div className="row mt-8">
        {tools.map((tool, i) => (
          <div
            key={tool.url}
            className="col-12 col-lg-6 mt-32"
            style={
              !showAll && i + 1 > maxInitialItems
                ? { display: 'none', visibility: 'hidden' }
                : {}
            }
          >
            <MarketingToolCard
              url={tool.url}
              logo={tool.logo && <ProjectImage image={tool?.logo} />}
              title={tool.title}
              price={tool.price}
              summary={tool.summary}
              visitToolSiteLinkText={visitToolSiteLinkText}
            />
          </div>
        ))}
      </div>

      {/* more load */}
      {true === false &&  <div className="row mt-40">
        <div className="col-12">
          <button
            type="button"
            onClick={() => setShowAll(true)}
            disabled={showAll}
          >
            {displayMoreButtonText}
            <Icon className="ml-8" name="bottom" />
          </button>
        </div>
      </div>}
    </section>
  )
}

export default List
