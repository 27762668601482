import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import sortByLodash from 'lodash/sortBy'
import mapLodash from 'lodash/map'
import findLodash from 'lodash/find'
import DatoCMSModel from '../models/DatoCMSModel'

import flow from 'lodash/fp/flow'
import entries from 'lodash/fp/entries'
import map from 'lodash/fp/map'
import groupBy from 'lodash/fp/groupBy'
import sortBy from 'lodash/fp/sortBy'

import Layout from '@c/layout'

import Breadcrumb from '@c/breadcrumb'
import List from '@v/marketing-tools/list'
import { graphql } from 'gatsby'
import Icon from '@c/icon'
import Hero from '@v/seo-check-list/hero'
import useResourcesFilter from '@h/use-resources-filter'

import { extractCategories } from '../utils/extract-unique-categories'

const Page = ({ data, pageContext }) => {
  const { page, tools } = data
  const model = new DatoCMSModel(page)
  const title = page.title
  const toolsPlain = useMemo(() => mapLodash(data.tools.edges, 'node'), [])
  const filter = useState({})
  const categories = sortByLodash(
    extractCategories(map('node')(tools.edges)),
    'orderNumber'
  )
  const filtered = useResourcesFilter(toolsPlain, filter, pageContext.locale)
  const toolsByCategory = flow(
    sortBy('category.orderNumber'),
    groupBy('category.title'),
    entries,
    map(([category_, tools_]) => [
      category_,
      sortByLodash(tools_, 'orderNumber')
    ]) // sort tools by orderNumber
  )(filtered)

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-start">
            <Breadcrumb
              className="pt-40"
              parentPages={[]}
              currentPage={title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>

      <Hero
        titleSmall={page.heroTitleSmall}
        titleBig={page.heroTitleBig}
        description={page.heroDescription}
        image={page.heroImage}
        imageAsset={page.heroImageAsset}
      />

      {/* page */}
      <div className="container mt-120">
        <div className="row">
          {/* sidebar */}
          {/* <div className="col-12 col-lg-4 d-none d-lg-block" dangerouslySetInnerHTML={{ __html: page.sideDescription }}></div> */}
          <div className="col-lg-2 mt-8 pl-0">
            <div className="d-none d-lg-block u-sticky scrollable-menu">
              <p className="fs-x-small c-text">{page.categoriesSectionTitle}</p>
              {categories.map((category, i) => {
                return (
                  <div key={i} className="content-jumplink pb-8 border-b-white">
                    <a
                      className="d-flex align-items-center py-8"
                      href={`#category-${category.slug}`}
                    >
                      <p>{category.title}</p>
                      <Icon className="d-none icon ml-auto" />
                    </a>
                  </div>
                )
              })}
            </div>
          </div>

          {/* content */}
          <div className="col-12 col-lg-10">
            {toolsByCategory.map(([category, tools_], i) => (
              <List
                key={category}
                id={`category-${
                  findLodash(categories, ['title', category]).slug
                }`}
                title={category}
                className={classNames(i > 0 && 'pt-120')}
                tools={tools_}
                displayMoreButtonText={page.displayMoreButtonText}
                visitToolSiteLinkText={page.visitToolSiteLinkText}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query MarketingToolsQuery($locale: String) {
    page: datoCmsMarketingToolsPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      heroTitleSmall
      heroTitleBig
      heroDescription
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
      sideDescription
      displayMoreButtonText
      visitToolSiteLinkText
      categoriesSectionTitle
    }

    tools: allDatoCmsMarketingTool(
      filter: { title: { regex: "/.+/" }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          summary: description
          price
          orderNumber
          url
          category {
            slug
            title
            orderNumber
          }
          logo {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
